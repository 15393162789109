
.filelist-container {
    /* margin-top: 20px; */
    background-color: #eaeaea;
    border: 1px solid #dbdbdb;
}

.filelist-container > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
}

.filelist-container > li + li {
    margin-top: 15px;
}

.filelist-info {
  display: flex;
  align-items: center;
}

.filelist-info > div {
    display: flex;
    flex-direction: column;
}

.filelist-info > div > span {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
}

.filelist-info > div > span > button {
    border: 0;
    background: transparent;
    color: #e57878;
    margin-left: 5px;
    cursor: pointer;
}

.filelist-preview {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    /* margin-right: 10px; */
}